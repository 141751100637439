<script setup>
import { computed, inject, ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import './FrontPage.scss'
import HeaderSearch from '@/components/header-search/header-search.vue'
import UserNavigation from '@/components/user-navigation/user-navigation.vue'


//variables
const store = useStore()


const isUserLogged = computed(() => store.getters['interfaceSettings/isUserLogged'])
const isMaintenance = computed(() => store.getters['interfaceSettings/isMaintenance'])
const userLoginInProgress = computed(() => {
	return store.getters['interfaceSettings/userLoginInProgress']
})
const userMenuOpened = computed(() => {
	return store.getters['interfaceSettings/userMenuOpened']
})
function handleLoginBtn() {
	store.commit('interfaceSettings/loginModalOpen', true)
}
function handleUserSettingsDropboxMouseLeave() {
	store.commit('interfaceSettings/userMenuOpened', false)
}

onMounted(() => {
	console.log(`mounted frontpage`)

})
onUnmounted(() => {
	console.log(`unmount frontpage`)
})
</script>
<template>
	<div class="top-headbox" v-if="isMaintenance === false">
		<div class="head-right">
			<template v-if="isUserLogged === true">
				<user-navigation />
			</template>
			<button v-else class="login-btn" type="button" @click="handleLoginBtn">
				<img src="@/assets/images/vector/user.svg" alt="" />
				<span v-if="userLoginInProgress === false">Login / Register</span>
				<span v-else>Loading user ...</span>
			</button>
		</div>
	</div>
	<div class="frontpage-center-box">
		<div class="container">
			<div class="search-wrapper">
				<div class="center-logo">
					<div class="logo-figure"><img src="@/assets/images/vector/arcella-logo-dark-circle.svg" alt="" /></div>
					<div class="logo-right">
						<div class="logo-text">
							<img src="@/assets/images/vector/arcella-logo-dark-text.svg" alt="" />
						</div>
						<span>Enterprise search</span>
					</div>
				</div>
				<template v-if="isMaintenance === true">
					<div class="maintenance-box" @click="store.commit('interfaceSettings/setMaintenance', false)">
						<p><b>Update is in progress... </b>Please check again in 30-60 minutes. <br><em>Have a great day!</em></p>
					</div>
				</template>
				<template v-else>
					<header-search :autofocus="true" :is-front="true" />

				</template>


			</div>
		</div>
	</div>
	<div class="front-footer">
		<a href="https://arcella.bio" target="_blank" class="logo">
			<span>Powered by</span>
			<img src="@/assets/images/vector/arcella-logo-dark-text.svg" alt="" />
		</a>
		<div class="footer-cent">
			<div class="eco">
				<i><img src="@/assets/images/vector/earth-day2.svg" alt=""></i>
				<span>At Arcella we are committed to give back to Earth and will contribute 1% of <br>our worldwide turnover to organisations working to protect the environment.</span>
			</div>

		</div>
		<div class="right">
			<span class="copy">© 2024 Arcella. All rights reserved.</span>
			<span class="copy">Company registration: 89351215</span>
		</div>
	</div>
</template>

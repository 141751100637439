<script setup>
import { onMounted, onUnmounted, onUpdated, computed, ref, inject, watch, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import ExpandedItem from '@/components/search-table/expanded-item/expanded-item.vue'

const store = useStore()
const props = defineProps({
	data: {
		type: Object,
		required: true
	},
	index: {
		type: Number,
		required: true
	},
	tableWidth: {
		type: Number,
		required: true
	},
	scrolledPX: {
		type: Number,
		required: true
	}
})
const readmoreText = ref('More info')
const isChildExpanded = ref(false)
const authorsCount = ref(0)
const isMoreAuthors = ref(false)
const isVisited = ref(false)
const emit = defineEmits(['checked'])
const searchText = computed(() => {
	return store.getters['search/searchText']
})
const selectedIds = computed(() => {
	return store.getters['search/selectedIds']
})
const tableColumns = computed(() => {
	return store.getters['search/allColumns']
})
const pmLink = computed(() => {
	return 'https://pubmed.ncbi.nlm.nih.gov/' + props.data.pmid
})
function short(string, maxChars, isAuthors) {
	//console.log(string)
	if (string.length > maxChars) {
		if (isAuthors) {
			let authorsArray = string.split(',')
			const maxAuthors = maxChars
			authorsCount.value = authorsArray.length - 1
			if (authorsCount.value > maxAuthors) {
				isMoreAuthors.value = true
			}
			//уменьшаем кол-во авторов, для вывода в основную колонку
			authorsArray = authorsArray.slice(0, maxAuthors)
			string = authorsArray.join(', ')
		} else {
			string = string.substring(0, maxChars) + '...'
		}
		string = string.replace(/,\s*$/, '')
	}
	return string
}
const isLoading = computed(() => {
	return store.getters['search/isLoading']
})
function addStrongToString(incomingSTR) {
	let searchWordArray = searchText.value.split(' ')
	let resultSTR
	searchText.value.split(' ').forEach((searchWord) => {
		if (incomingSTR) {
			resultSTR = incomingSTR.replace(new RegExp(`\\b(${searchWordArray.join('|')})\\b`, 'ig'), '<b>$1</b>')
		}
	})
	return resultSTR
}
function handleChecked() {
	emit('checked')
}
function handleExpandRow() {
	isChildExpanded.value = !isChildExpanded.value
	isVisited.value = true
}
onUpdated((gav) => {
	//console.log(props.scrollingAmount)
})
onMounted(() => {
	// console.log(props.data)
})
watch(
	() => isChildExpanded.value,
	(isExpanded) => {
		if (isExpanded) {
			readmoreText.value = 'Close'
		} else {
			readmoreText.value = 'More info'
		}
	},
	{ immediate: false }
)
function handleCloseExpanded() {
	isChildExpanded.value = false
	setTimeout(() => {}, 1000)
}
</script>

<template>
	<tr role="row" :class="[{ isVisited: isVisited }, { selected: selectedIds.includes(props.index) === true }]">
		<th role="rowheader" scope="row" class="sticky-column">
			<div class="sticky-column-box">
				<div class="sentence-content">
					<p v-html="addStrongToString(props.data.data.Sentence)"></p>
				</div>
				<div class="sticky-column-footer">
					<div class="panel-elements">
						<div class="result-sentnce-selector">
							<label class="custom-checkbox">
								<input
									type="checkbox"
									name="sentenceSelects"
									@change="handleChecked"
									:checked="selectedIds.includes(props.index) === true"
									value="1"
								/>
								<i class="ch-btn"></i>
								<span class="checkbox-holder">{{ props.index }}</span>
							</label>
						</div>
						<div class="sentence-props">
							<div
								class="prop textAvail hasAbstract"
								v-tooltip.bottom="'Abstract'"
								v-if="props.data.isAbstract === true"
							>
								A
							</div>
							<div
								class="prop textAvail"
								v-tooltip.class="'bla'"
								v-tooltip.bottom="{ value: 'Full Text' }"
								v-else
							>
								F
							</div>
							<div class="prop lock-status" v-tooltip.bottom="'Open access'" v-if="props.data.isopen.open">
								<img src="@/assets/images/vector/lock.svg" alt="" />
							</div>
							<div class="prop lock-status locked" v-tooltip.bottom="'Closed access'" v-else>
								<img src="@/assets/images/vector/lock-active.svg" alt="" />
							</div>
							<div class="prop citations" v-tooltip.bottom="'Number of citations'">
								<i><img src="@/assets/images/vector/quote-new.svg" alt="" /></i>
								<span>{{ props.data.citations }}</span>
							</div>
							<div class="prop authors" v-tooltip.bottom="{ value: 'Number of authors', hideDelay: 0 }">
								<i><img src="@/assets/images/vector/user.svg" alt="" /></i>
								<span>{{ authorsCount }}</span>
							</div>
							<a :href="pmLink" class="prop link" target="_blank" v-tooltip.bottom="'Pubmed link'">
								<i><img src="@/assets/images/vector/icon-link.svg" alt="" /></i>
							</a>
						</div>
					</div>
					<button class="more-btn" type="button" :class="{ active: isChildExpanded }" @click="handleExpandRow">
						<span>{{ readmoreText }}</span>
						<i></i>
					</button>
				</div>
			</div>
		</th>

		<template v-for="(column, i) of tableColumns" :key="i">
			<td :aria-colindex="i" role="cell" :class="'cell-' + column.name.toLowerCase()" v-if="column.active === true">
				<template v-if="column.isDefault === true">
					<p v-if="props.data.data[column.name]">
						<template v-if="column.name === 'Title'">
							{{ short(props.data.data[column.name], 300) }}
						</template>
						<template v-else-if="column.name === 'Authors'">
							{{ short(props.data.data[column.name], 6, true) }}
							<b v-if="isMoreAuthors">...</b>
						</template>
						<template v-else>
							{{ short(props.data.data[column.name], 200) }}
						</template>
					</p>
					<p class="nohave" v-else>-</p>
				</template>
				<template v-else>
					<template v-if="props.data.data[column.name]">
						<div
							class="_g-lazy-block mod-column nodefault"
							:class="{ notLoaded: props.data.data[column.name].isLoaded !== true }"
						></div>
						<template v-if="props.data.data[column.name].isLoaded === true">

							<template v-if="column.name === 'Species' || column.name === 'Tissues' || column.name === 'Emails'" >
								<template v-if="props.data.data[column.name].result && props.data.data[column.name].result.length >0" v-for="(item, i) of props.data.data[column.name].result" :key="i">

									<span class="arrItem">{{item}}</span>
									<template v-if="i+1 < props.data.data[column.name].result.length"><em>, </em></template>
								</template>


								<template v-else>
									<span>-</span>
								</template>

							</template>

							<template v-else>
								<p v-if="props.data.data[column.name].result">
									{{ short(props.data.data[column.name].result, 200) }}
								</p>
								<p class="nohave" v-else>-</p>
							</template>

						</template>
					</template>


				</template>
			</td>
		</template>
	</tr>

	<expanded-item
		v-if="isChildExpanded === true"
		:table-width="props.tableWidth"
		:data="props.data"
		:is-open="props.data.isopen.open"
		:scrolling-amount="props.scrolledPX"
		:pmid="props.data.pmid"
		@close="handleCloseExpanded"
	/>
</template>

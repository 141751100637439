export function downloadXLSX(resultsData, columns, filename) {
	let headersArray = ['Sentence'] // Sentence is default value, we fill that in any case
	let dataArray = []
	columns.map((item) => {
		if (item.active === true) {
			headersArray.push(item.name)
		}
	})
	headersArray.splice(1, 0, 'doi')
	dataArray.push(headersArray)







	function excelTable() {
		let tableHeadColumns = ''
		let tableBodyColumns = ``

		headersArray.map((headerItem) => {
			tableHeadColumns += '<th>'+ headerItem+ '</th>'
		})
		resultsData.forEach((item) => {
			tableBodyColumns += '<tr>'
			headersArray.map((header) => {
				tableBodyColumns += '<td>'
				if (header in item.data) {

					//если у нас доп колонка (в которой объект у меня всегда), то тогда берем данные с объекта
					if (typeof item.data[header] == 'object') {
						if (item.data[header].result) {
							if(header === 'Emails'){
								let emails = ''
								item.data[header].result.forEach((item) => {
									emails += item +'<br />'

								})
								tableBodyColumns += emails
							}
							else if(header === 'Institutions'){
								let institutions = ''
								const instArray = item.data[header].result.split('\n').filter(item => item.trim() !== '')
								instArray.forEach((item) => {
									institutions += `${item}<br />`
								})
								tableBodyColumns += institutions
							}

							else{
								tableBodyColumns += item.data[header].result
							}

						} else {
							tableBodyColumns += ''
						}
					}
					//если простая дефолтная колонка
					else {
						tableBodyColumns += item.data[header]
					}

				}
				if (header === 'doi') {
					tableBodyColumns += 'https://doi.org/' + item.doi
				}
				if (header === 'emails') {
					tableBodyColumns += 'asdf'
				}
				tableBodyColumns += '</td>'

			})
			tableBodyColumns += '</tr>'
		})
		const tableHead = `<thead><tr>${tableHeadColumns}</tr></thead>`
		const tableBody = `<tbody>${tableBodyColumns}</tbody>`
		const table = `${tableHead} ${tableBody}`
		return `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>${table}</table></body></html>`
	}

	var tableToExcel = function () {
		let tableHtmlCode = excelTable()
		var uri = 'data:application/vnd.ms-excel;base64,',
			template = tableHtmlCode,
			base64 = function (s) {
				return window.btoa(unescape(encodeURIComponent(s)))
			},
			format = function (s, c) {
				return s.replace(/{(\w+)}/g, function (m, p) {
					return c[p]
				})
			}

		const ctx = { worksheet: name || 'Worksheet', table: 'result Table'}
		const a = document.createElement('A')
		a.setAttribute('href', uri + base64(format(template, ctx)))
		a.setAttribute('download', filename)
		document.body.appendChild(a)
		a.click()
	}
	tableToExcel()
}



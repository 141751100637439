<script setup>
import './pricing-window.scss'
import { onMounted, defineComponent,  onBeforeMount, onUnmounted, computed, onUpdated, inject, watch, ref } from 'vue'
import ModalBox from '@/components/modal-box/modal-box.vue'
import { useStore } from 'vuex'
import { api } from '@/arcella-api/api.js'
const store = useStore()
const redirWindowOpen = ref(false)
let userSessionKey = ref()
let stripeError = ref()
let userKeyLoaded = ref(false)
const basicStripePriceId = 'price_1Q8K15Eyawn8ySvcPTGTSSGo'
const advancedStripePriceId = 'price_1Q9m67Eyawn8ySvcS7bnIe17'
// const monthStripePriceId = 'price_1PXrmDEyawn8ySvcAM9WcOPR'
// const yearStripePriceId = 'price_1PXqwsEyawn8ySvcGpWpfDdY'
const stripeMonthUrl = ref()
const stripeYearUrl = ref()
onMounted(() => {
	api.userservices.stripeSessionSecret().then((response) => {
		userSessionKey.value = response.data
		console.log(userSessionKey.value)
		userKeyLoaded.value = true
	})

})
function handleModalClose() {
	store.commit('interfaceSettings/pricingWindowShowed', false)
}
function handlePayBtn(priceID) {
	redirWindowOpen.value = true
	console.log(priceID)
	api.userservices.stripeSessionGenerateLink(priceID).then((response) => {
		if(response.data.length > 0){
			//check when payment is done!
			window.location.href = response.data;
		}
		else{
			stripeError.value = response.data
			console.log(`something is wrong: response is:`)
			console.log(response.data)
		}
	})
}
function handleContactBtn() {
	store.commit('interfaceSettings/pricingWindowShowed', false)
	store.commit('interfaceSettings/contactWindowShowed', true)
}
</script>
<template>
	<modal-box class-name="pricing-modal" @close="handleModalClose">
		<div class="modal-heading">
			<span class="modal-window-title">Pricing</span>
		</div>

		<div class="redir-window-box"  :class="{showed: redirWindowOpen === true}" >
			<i><img src="@/assets/images/vector/loading-columns.svg" alt="" /></i>
			<span class="redir-text"><span>Redirecting to <em>Stripe</em>...</span> </span>
			<span class="errow-box" v-if="stripeError">{{stripeError}}</span>
		</div>
		<div class="pricing-table">
			<div class="pricing-item featured">
				<div class="item-holder">
					<div class="featured-label"></div>
					<div class="item-frame">
						<div class="item-heading-box">
							<span class="plan-name">Annual plan</span>
						</div>
						<div class="price-amount-box">
							<div class="price"><b>€1995</b><span>/year</span></div>
						</div>
						<div class="get-wrapper">
							<button @click="handlePayBtn(basicStripePriceId)" class="get-btn" type="button"><span>Get 1-year plan</span></button>
						</div>
						<span class="limited-price">1 user</span>
					</div>
				</div>
			</div>
			<div class="pricing-item featured">
				<div class="item-holder">
					<div class="featured-label"></div>
					<div class="item-frame">
						<div class="item-heading-box">
							<span class="plan-name">Annual plan</span>
						</div>
						<div class="price-amount-box">
							<div class="price"><b>€3995</b><span>/year</span></div>
						</div>
						<div class="get-wrapper">
							<button @click="handlePayBtn(advancedStripePriceId)" class="get-btn" type="button"><span>Get 1-year plan</span></button>
						</div>
						<span class="limited-price">up to 5 users</span>
					</div>
				</div>
			</div>
			<div class="pricing-item featured">
				<div class="item-holder">
					<div class="featured-label"></div>
					<div class="item-frame">
						<div class="item-heading-box">
							<span class="plan-name">Enterprise</span>
						</div>
						<div class="price-amount-box">
							<div class="price">Please contact us <br>for a quote</div>
						</div>
						<div class="get-wrapper">
							<button class="get-btn" @click="handleContactBtn" type="button"><span>Contact us</span></button>
						</div>
						<span class="limited-price">more than 5 users</span>

					</div>
				</div>
			</div>

		</div>
		<div class="pricing-info-bottom-row">
			<div class="what-get-box">
				<span class="get-title"><b>Subscription includes:</b></span>
				<ul class="abilites-list">
					<li>Online customer support</li>
					<li>Unlimited export XLS, TSV, CSV</li>
					<li>Daily updates</li>
					<li>100,000 sentences/images per month</li>
				</ul>
			</div>
		</div>
		<div class="eco-box">
			<i><img src="@/assets/images/vector/earth-day2.svg" alt=""></i>
			<span>At Arcella we are committed to give back to Earth and will contribute 1% <br>of our worldwide turnover to organisations working to protect the environment.</span>
		</div>

	</modal-box>
</template>

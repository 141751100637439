<script setup>
import { inject, computed, reactive, ref, onMounted, nextTick, watch, onBeforeMount, onUnmounted } from 'vue'
import './Sidebar.scss'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import HistogramSlider from "vue3-histogram-slider";
import "vue3-histogram-slider/dist/histogram-slider.css";
import { useStore } from 'vuex'
const store = useStore()
const sidebarElem = ref()
const vueSliderCount = ref()
const papersResultCount = ref(25)
const sidebarPaddingTop = ref(100)

//articleType
const isArticleTypeOpened = ref(true)
const articleTypeCheckboxes = ref(['Meta-Analysis', 'Review', 'Systematic Review', 'Preprint'])
const articleTypeSelected = ref([])

//Text Aviability
const isTextAviabilityOpened = ref(true)
const textAviabilitySelected = computed(() => store.getters['search/textAvailabilitySelected'])
//figures type
const isFiguresTypeOpened = ref(false)
const textAviabilityVariants = computed(() => {
	return store.getters['search/textAvailabilityVariants']
})
const typeOfFigureCheckboxes = computed(() => {
	return store.getters['search/typeOfFigure']
})
const typeOfFigureSelected = ref([])

//history Slider

const histogramData = computed(() => {
	let data = store.getters['search/histogramData']
	return data.map(d => new Date(d, 0))
})
const histogramYearMin = computed(() => {
	return store.getters['search/histogramMin']
})
const histogramYearMax = computed(() => {
	return store.getters['search/histogramMax']
})
const isHistogramLoaded = computed(() => {
	return store.getters['search/histogramLoaded']
})
const isSearchByUpload = computed(() => {
	return store.getters['search/searchByFigureUpload']
})
const wantUpdateHistogram = computed(() => {
	return store.getters['search/wantUpdateHistogram']
})
const isFigureSearch = computed(() => {
	return store.getters['search/isFigureSearch']
})
const articlesSelectedStore = computed(() => {
	return store.getters['search/articleTypeSelected']
})
function  prettify(ts) {
	return new Date(ts).toLocaleDateString("en", {
		year: "numeric",
	});
}
const isSidebarHidden = computed(() => {
	return store.getters['interfaceSettings/hiddenSidebar']
})
onBeforeMount(() => {
	window.addEventListener('scroll', handleWindowScroll)
})
onMounted(() => {
	typeOfFigureSelected.value = typeOfFigureCheckboxes.value

})
onUnmounted(() => {
	window.removeEventListener('scroll', handleWindowScroll)
})

watch(
	() => isSidebarHidden.value,
	(isHidden) => {
		if (isHidden === true) {
			document.body.classList.remove('sidebar-showed')
		} else {
			document.body.classList.add('sidebar-showed')
		}
	},
	{ immediate: true }
)
watch(
	() => articleTypeSelected.value,
	(selectedCheckboxes) => {
		store.commit('search/updateArticleTypeSelected', selectedCheckboxes)
		store.commit('search/wantResetPagination', true)
		store.commit('search/wantUpdateHistogram', true)
		store.dispatch('search/search')
	},
	{ immediate: false, deep:true }
)
watch(
	() => typeOfFigureCheckboxes.value,
	(selectedCheckboxes) => {
		store.commit('search/wantResetPagination', true)
		store.commit('search/wantUpdateHistogram', true)
		store.dispatch('search/search')
	},
	{ immediate: false, deep:true }
)

function handleWindowScroll(e) {
	let scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
	const scrollYFake = document.documentElement.style.getPropertyValue('--scroll-y-fake')

	if(scrollYFake){
		scrollY = scrollYFake
	}
	if (scrollY < 100) {
		sidebarElem.value.classList.remove('sidebar-fix')
		sidebarPaddingTop.value = 100 - scrollY
	} else {
		sidebarElem.value.classList.add('sidebar-fix')
	}

}
function toggleSidebar() {
	store.commit('interfaceSettings/hideSidebar', !isSidebarHidden.value)
}
function handleSelectArticleAll() {
	articleTypeSelected.value = articleTypeCheckboxes.value
}
function handleSelectArticleNone() {
	articleTypeSelected.value = []
}
function handleSelectArticleCheckbox(element) {

	let checkedText = articleTypeCheckboxes.value[element.target.value]
	if (element.target.checked === true) {
		if (!articleTypeSelected.value.includes(articleTypeCheckboxes[element.target.value])) {
			articleTypeSelected.value.push(checkedText)
		}
	} else {
		articleTypeSelected.value = articleTypeSelected.value.filter((e) => e !== checkedText)
	}

}

function handleSelectTextAviRadiobutton(element) {
	store.commit('search/updateTextAvailabilitySelected', element.target.value)
	store.commit('search/wantResetPagination', true)
	store.commit('search/wantUpdateHistogram', true)
	store.dispatch('search/search')
}

function changeResultsCount(){
	const sliderValue = vueSliderCount.value.getValue()
	store.commit('search/changeResultsPerPage', sliderValue)
	store.commit('search/wantResetPagination', true)
	store.dispatch('search/search')
}
function handleHistogramChange(e){
	store.commit('search/histogramChanged', true)
	store.commit('search/updateYearFrom', e.from_pretty)
	store.commit('search/updateYearTo', e.to_pretty)
	store.commit('search/wantResetPagination', true)
	store.dispatch('search/search')
}

function handleSelectFiguresAll() {
	typeOfFigureSelected.value.map(chk => chk.selected = true)
}
function handleSelectFiguresNone() {
	typeOfFigureSelected.value.map(chk => chk.selected = false)
}
function handleSelectFiguresCheckbox(element, index) {
	typeOfFigureSelected.value[index].selected = element.target.checked
	store.commit('search/updateTypeOfFigures', typeOfFigureSelected.value)
}
</script>
<template>
	<aside class="filter-sidebar" ref="sidebarElem" :style="{ paddingTop: sidebarPaddingTop + 'px' }">
		<button class="show-sidebar-btn jsSidebarSwitch" type="button" @click="toggleSidebar">
			<span>
				<i><img src="@/assets/images/vector/short-arrow.svg" alt="" /></i>
				<i><img src="@/assets/images/vector/short-arrow.svg" alt="" /></i>
			</span>
		</button>
		<div class="sidebar-holder">
			<div class="widget-box">
				<span class="widget-title">Information from papers</span>
				<div class="slider-simple">
					<vue-slider
						ref="vueSliderCount"
						:max="100"
						:data="[100, 250, 500, 750, 1000]"
						:marks="[100, 250, 500, 750, 1000]"
						:height="8"
						:dot-size="18"
						:contained="false"
						:model-value="papersResultCount"
						@drag-end="changeResultsCount"
						:drag-on-click="true"
						:tooltip="'none'"
					/>
				</div>
			</div>
			<div class="widget-box">
				<span class="widget-title">Results by year</span>
				<div class="history-slider">
					<HistogramSlider
						v-if="isHistogramLoaded"
						width="100%"
						:bar-height="100"
						:handleSize="20"
						:keyboard="false"
						:histSliderGap="0"
						:barRadius="3"
						:barGap="5"
						:barWidth="3"
						:lineHeight="8"
						:data="histogramData"
						:prettify="prettify"
						:drag-interval="true"
						:force-edges="true"
						primaryColor="#14C68C"
						@finish="handleHistogramChange"
						:colors="['#3ED67B', '#4facfe']"
						:min="new Date(histogramYearMin, 1, 1).valueOf()"
						:max="new Date(histogramYearMax, 1, 1).valueOf()"
					/>
					<div class="histogram-lazy _g-lazy-block" v-else></div>
				</div>
			</div>

			<div class="widget-box" v-if="isFigureSearch === false">
				<button
					class="widget-collapse-title"
					type="button"
					:class="{ active: isTextAviabilityOpened }"
					@click="isTextAviabilityOpened = !isTextAviabilityOpened"
				>
					<span>Text availability </span>
				</button>
				<slide-up-down :active="isTextAviabilityOpened" :duration="500">
					<div class="widget-collapse-box">
						<div class="checks-list">

							<template v-for="(item, i) of textAviabilityVariants" :key="i">
								<label class="custom-checkbox type-radio">
									<input
										type="radio"
										:checked="textAviabilitySelected === item.value"
										@change="handleSelectTextAviRadiobutton"
										name="textAviability"
										:value="item.value"
									/>
									<i class="ch-btn"></i>
									<span class="checkbox-holder">{{ item.title }}</span>
								</label>
							</template>
						</div>
					</div>
				</slide-up-down>

			</div>

		</div>
	</aside>
</template>

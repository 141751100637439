<script setup>
import './arc-gallery.scss'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

import { onMounted, onUnmounted, computed, ref, onUpdated, inject, watch } from 'vue'
import  { useStore } from 'vuex'
import { EffectFade, Mousewheel, Parallax } from 'swiper/modules'
import { api } from '@/arcella-api/api.js'
const store = useStore()
const props = defineProps({
	openIndex: {
		type: Number,
		required: true,
		default: 0
	}
})
let swiperInstance = null
const searchText = computed(() => {
	return store.getters['search/searchText']
})
const galleryData = computed(() => {
	return store.getters['search/resultsData']
})
function addStrongToString(incomingSTR) {
	let searchWordArray = searchText.value.split(' ')
	let resultSTR
	searchText.value.split(' ').forEach((searchWord) => {
		if (incomingSTR) {
			resultSTR = incomingSTR.replace(new RegExp(`\\b(${searchWordArray.join('|')})\\b`, 'ig'), '<b>$1</b>')
		}
	})
	return resultSTR
}
function pmLink(pmid) {
	return 'https://pubmed.ncbi.nlm.nih.gov/' + pmid
}
const currentSlide = ref(1)
const amountOfSlides = ref(null)
const itemBlock = ref()

onMounted(() => {
	loadAbstract(props.openIndex)
	currentSlide.value = props.openIndex + 1
	amountOfSlides.value = galleryData.value.length
})

onUnmounted(() => {
	swiperInstance.destroy()
})

const onSwiperInit = (e) => {
	swiperInstance = e
}

const onSlideChange = (e) => {
	const activeIndex = e.activeIndex
	currentSlide.value = activeIndex + 1
	loadAbstract(activeIndex)
}
function loadAbstract(index) {
	if (!galleryData.value[index].abstractdata) {
		api.expanded.getAbstract(galleryData.value[index].pmid).then((data) => {

			galleryData.value[index].abstractdata = data.data
			galleryData.value[index].abstractdata.loaded = true
		})
	}
}
const userApiKey = computed(() => store.getters['interfaceSettings/userData'].apiKey)
function computedImageFromApi(imageUrl){
	return `https://arcella.bio/additional?key=${userApiKey.value}&image=${imageUrl}`
}
function handlePicLoaded(elem, index) {
	galleryData.value[index].loaded = true
}
</script>

<template>
	<div class="arc-gallery">
		<div class="pagination">
			<div class="pagination-row">
				<button class="prev-next-btn prev" type="button" @click="swiperInstance.slidePrev()">
					<img src="@/assets/images/vector/arrow-gallery.svg" alt="" />
				</button>
				<div class="slides">
					<span class="current">{{ currentSlide }}</span>
					<span class="separator">/</span>
					<span class="all">{{ amountOfSlides }}</span>
				</div>
				<button class="prev-next-btn next" type="button" @click="swiperInstance.slideNext()">
					<img src="@/assets/images/vector/arrow-gallery.svg" alt="" />
				</button>
			</div>
		</div>
		<button class="nav-btn prev" type="button" @click="swiperInstance.slidePrev()">
			<img src="@/assets/images/vector/arrow-gallery.svg" alt="" />
		</button>
		<button class="nav-btn next" type="button" @click="swiperInstance.slideNext()">
			<img src="@/assets/images/vector/arrow-gallery.svg" alt="" />
		</button>
		<button class="close-btn" type="button" @click="store.commit('search/arcGalleryOpen', null)"><span></span></button>

		<div class="arc-gallery-holder">
			<swiper
				:slides-per-view="1"
				:initial-slide="props.openIndex"
				:modules="[EffectFade, Parallax, Mousewheel]"
				effect="fade"
				focusable-elements="p,dd"
				:follow-finger="false"
				:mousewheel="{
					enabled: false,
					releaseOnEdges: true,
					sensitivity: 6
				}"
				:fade-effect="{
					crossFade: true
				}"
				:parallax="true"
				@init="onSwiperInit"
				@slide-change="onSlideChange"
			>
				<swiper-slide v-for="(figureItem, i) of galleryData" :key="i">
					<div class="swiper-slide-row">
						<div class="left-col">
							<div class="pic" data-swiper-parallax-opacity="0" data-swiper-parallax-delay="0.3">
								<div class="pic-frame" :class="{ loaded: figureItem.loaded === true }">
									<img @load="handlePicLoaded($event, i)" :src="computedImageFromApi(figureItem.data.figureurl)" :alt="figureItem.caption" />
									<div class="_g-lazy-block"></div>
								</div>
								<span class="figure-name">{{ figureItem.data.figurelabel }}</span>
							</div>
						</div>
						<div class="right-col">
							<div class="slide-content-wrapper">
								<div class="slide-content-holder" data-swiper-parallax="-50" data-swiper-parallax-opacity="0">
									<a
										target="_blank"
										:href="pmLink(figureItem.data.pmid)"
										class="article-name"
										v-if="figureItem.abstractdata"
									>
										<span v-if="figureItem.abstractdata">{{ figureItem.abstractdata.title }}</span>
									</a>
									<div v-else class="_g-lazy-block lazy-title"></div>

									<dl>
										<dt>PMID:</dt>
										<dd>{{ figureItem.data.pmid }}</dd>
										<dt>DOI:</dt>
										<dd>
											<a :href="'https://doi.org/'+figureItem.doi" target="_blank">{{ figureItem.doi }}</a>
										</dd>
										<dt>Year:</dt>
										<dd>{{ figureItem.data.Year }}</dd>
										<dt>Journal:</dt>
										<dd>
											<span  v-if="figureItem.abstractdata">{{figureItem.abstractdata.jtitle}}</span>
											<div class="lazy-block" v-else>
												<p class="_g-lazy-block"></p>
											</div>
										</dd>
										<dt>Authors:</dt>
										<dd>
											<span  v-if="figureItem.abstractdata">{{figureItem.abstractdata.authors}}</span>
											<div class="lazy-block" v-else>
												<p class="_g-lazy-block lazy-authors"></p>
											</div>
										</dd>
									</dl>
									<div class="item-block" ref="itemBlock">
										<span class="block-title">Caption</span>
										<div class="abstract-wrap">

											<p v-html="addStrongToString(figureItem.data.figurecaption)"></p>
										</div>
									</div>
									<div class="item-block">
										<span class="block-title">Abstract</span>
										<div class="abstract-wrap" v-if="figureItem.abstractdata">
											<p v-if="figureItem.abstractdata.contents">{{ figureItem.abstractdata.contents }}</p>
											<p v-else>Abstract no founded</p>
										</div>
										<div class="lazy-block-text" v-else>
											<p class="_g-lazy-block" v-for="(item, i) of 8"></p>
										</div>
									</div>
									<div class="item-block">
										<span class="block-title">Publication types</span>
										<p><b>Research support(TBD)</b>, <b>Non Goverment(TBD)</b>,</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>

import { api } from '@/arcella-api/api'

Array.prototype.hasMin = function (attrib) {
	return (
		(this.length &&
			this.reduce(function (prev, curr) {
				return prev[attrib] < curr[attrib] ? prev : curr
			})) ||
		null
	)
}
Array.prototype.hasMax = function (attrib) {
	return (
		(this.length &&
			this.reduce(function (prev, curr) {
				return prev[attrib] > curr[attrib] ? prev : curr
			})) ||
		null
	)
}

export default {
	namespaced: true,
	state() {
		return {
			isFigureSearch: false,
			searchText: null,
			resultsPerPage: 100,
			totalPages: 0,
			isNothingFound: false,
			currentResultPage: 1,
			resultsFrom: 0,
			totalResults: 0,
			wantResetPagination: true,
			isMustPay: false,
			columns: [
				{ id: 1, name: 'Year', slug:'year', active: true, isDefault: true, loaded: false },
				{ id: 2, name: 'Title', slug:'title', active: true, isDefault: true, loaded: false },
				{ id: 3, name: 'Authors', slug:'authors', active: true, isDefault: true, loaded: false },
				{ id: 4, name: 'Institutions', slug:'institutions', active: false, isDefault: false, loaded: false },
				{ id: 5, name: 'Emails',slug:'emails', active: false, isDefault: false, loaded: false },
				{ id: 6, name: 'Species', slug:'species', active: false, isDefault: false, loaded: false },
				{ id: 7, name: 'Tissues', slug:'tissues', active: false, isDefault: false, loaded: false },
				// { id: 8, name: 'Assays', slug:'assays', active: false, isDefault: false, loaded: false },
				// { id: 9, name: 'Applications', slug:'apps', active: false, isDefault: false, loaded: false },
				// { id: 10, name: 'SNPs', slug:'snp', active: false, isDefault: false, loaded: false },

			],
			selectedIds: [],
			articleTypeSelected: [],
			textAvailability: [
				{ title: 'Abstract + Full Text', value: 'allmixed' },
				{ title: 'Full Text', value: 'full' },
				{ title: 'Abstract', value: 'abs' },
			],
			textAvailabilitySelected: 'allmixed',
			typeOfFigure: [
				{ name: 'heatmap', selected: false },
				{ name: 'bar chart', selected: false },
				{ name: 'pie chart', selected: false },
				{ name: 'western blot', selected: false },
				{ name: 'line graphs', selected: false },
				{ name: 'imunnochemistry', selected: false },
				// { name: 'chemical formula', selected: false },
				{ name: 'flow chart & workflow', selected: false },
				{ name: 'mri and ct', selected: false },
				{ name: 'network pathway', selected: false }
			],
			results: null,
			updateHistogram: true,
			histogram: {
				min: null,
				max: null,
				data: [1971, 1975, 1982, 1988, 1990, 1990, 2000, 2011, 2012, 2013, 2014, 2016, 2016, 2016, 2018, 2024],
				loaded: false,
				changed:false
			},
			sortFields: {
				available: [
					{
						name: 'Newest first',
						query: 'Newest first'
					},
					{
						name: 'Oldest first',
						query: 'Oldest first'
					},
					{
						name: 'More participants',
						query: 'Number of participants&sortdirection=desc'
					},
					{
						name: 'Less participants',
						query: 'Number of participants&sortdirection=asc'
					},
					{
						name: 'More citations',
						query: 'Number of citations&sortdirection=desc'
					},
					{
						name: 'Less citations',
						query: 'Number of citation&sortdirection=asc'
					}
				]
			},
			sortField: {
				name: 'Default',
				query: null
			},
			loadingInProgress: false,
			loadedAdditionColumn: true,
			figuresGallery: null,
			galleryOpen: null,
			arcGalleryData: null,
			arcGalleryOpen: null,
			searchByFigureUpload: false,
			searchByUploadModalOpen: false,
			uploadedFileToSearch: null,
			figureUploadSearchID: null
		}
	},

	actions: {
		search({ state, commit, dispatch }) {
			state.loadingInProgress = true
			state.isMustPay = false
			commit('resetLoadingColumns')
			commit('resetSearchResults')
			if (state.searchByFigureUpload === true) {
				//search by upload
				commit('searchText', null)
				commit('isFigureSearch', true)

				//clean search (file just uploaded)
				if(state.uploadedFileToSearch){
					dispatch('uploadSearch', state.uploadedFileToSearch)
				}
				else{
					dispatch('searchBySearchID')
				}
				//search using searchID
			} else {
				//text or figure search
				state.histogram.loaded = state.updateHistogram === false
				if (state.isFigureSearch === true) {
					//figure search
					dispatch('figureSearch')
				}
				if (state.isFigureSearch === false) {
					//text search
					dispatch('textSearch')
				}
			}
			if(state.isMustPay === true){
				commit('searchByFigureModalOpen', false)
			}

		},
		textSearch({ state, commit, dispatch }) {
			return api.search
				.searchText(
					state.searchText,
					state.resultsPerPage,
					state.histogram.min,
					state.histogram.max,
					state.histogram.changed,
					state.articleTypeSelected,
					state.textAvailabilitySelected,
					state.resultsFrom,
					state.sortField,
				)
				.then((data) => {
					//console.log(data.data)
					if(data.data === 'trial ended'){
						state.isMustPay = true
						state.loadingInProgress = false
					}
					else{
						state.isMustPay = false
						if (data.data.totalresults > 0) {
							commit('isNothingFound', false)
							commit('updateSearchResults', data.data)
							dispatch('singleColSearch')
							if (state.updateHistogram === true) {
								api.search.getYears(state.searchText).then((data) => {
									commit('updateHistogram', data.data)
									commit('wantUpdateHistogram', false)
								})
							} else {
								state.histogram.loaded = true
							}
						} else {
							state.loadingInProgress = false
							commit('isNothingFound', true)
						}
					}

				})

		},

		figureSearch({ state, commit, dispatch }) {
			return api.search
				.searchFigure(
					state.searchText,
					state.resultsPerPage,
					state.histogram.min,
					state.histogram.max,
					state.histogram.changed,
					state.resultsFrom,
					state.sortField,
					state.articleTypeSelected,
					state.typeOfFigure
				)
				.then((data) => {
					if(data.data === 'trial ended'){
						state.isMustPay = true
						state.loadingInProgress = false
						console.log(`muspay`)
					}
					else{
						if (data.data.totalresults > 0) {
							commit('isNothingFound', false)
							commit('updateSearchResults', data.data)
							if (state.updateHistogram === true) {
								api.search.getYears(state.searchText).then((data) => {
									commit('updateHistogram', data.data)
									commit('wantUpdateHistogram', false)
								})
							} else {
								state.histogram.loaded = true
							}
						} else {
							state.loadingInProgress = false
							commit('isNothingFound', true)
						}
					}

				})

		},

		singleColSearch({ state, commit }, value) {
			//если у нас отмечены не дефолтные колонки, то их нужно грузить отдельными запросами всегда, и при
			//добавлении и при обычном поиске, поэтому вычисляем что грузить доп, и если что то нужно то грузим с API

			state.columns.map((item) => {

				if (item.isDefault === false && item.active === true && item.loaded === false) {
					let columnsLoaded = 0
					state.results.map((itemResult) => {

						itemResult.data[item.name] = { isLoaded: false, result: null }

						api.search.searchSingleColumn(itemResult.pmid, item.slug.toLowerCase()).then((dataSingleCol) => {

							itemResult.data[item.name].isLoaded = true
							if(item.slug === 'emails' ||  item.slug === 'species' || item.slug === 'tissues' ){

								itemResult.data[item.name].result = dataSingleCol.data.split('\n').filter(item => item.trim() !== '')
							}
							else{
								itemResult.data[item.name].result = dataSingleCol.data
							}

							columnsLoaded += 1
							if (state.results && state.results.length === columnsLoaded ) {
								commit('updateLoadingColumn', [item.id, true])
							}
						})

					})
				}
			})

		},
		addColumn({ state, commit, dispatch }, value) {
			state.columns.find(({ id }) => id === value).active = true
				dispatch('singleColSearch')


		},

		uploadSearch({ state, commit, dispatch }, file) {
			state.uploadedFileToSearch = null
			return api.advancedSimilarity.searchByUplolad(file).then((data) => {
				if(data.data === 'trial ended'){
					state.isMustPay = true
					state.loadingInProgress = false
				}
				else{
					commit('figureUploadSearchID', data.data.searchID)
					commit('updateResultsByUploadImage', data.data)
					api.advancedSimilarity.getDates(state.figureUploadSearchID).then((yearsData) => {
						commit('updateHistogram', yearsData.data)
						commit('wantUpdateHistogram', false)
					})
				}


			})
		},
		searchBySearchID({ state, commit, dispatch }) {
			return api.advancedSimilarity
				.searchBySearchID(
					state.figureUploadSearchID,
					state.resultsPerPage,
					state.resultsFrom,
					state.histogram.min,
					state.histogram.max,
					state.histogram.changed,
					state.articleTypeSelected,
					state.sortField,
					state.isFigureSearch,
					state.typeOfFigure
				)
				.then((data) => {
					console.log(data.data)
					if(data.data === 'trial ended'){
						state.isMustPay = true
						state.loadingInProgress = false
						commit('searchByFigureModalOpen', false)
					}
					else{
						commit('figureUploadSearchID', data.data.searchID)
						if (data.data.totalresults > 0) {
							commit('isNothingFound', false)
							commit('updateSearchResults', data.data)
							if (state.updateHistogram === true) {
								api.advancedSimilarity.getDates(state.figureUploadSearchID).then((data) => {
									commit('updateHistogram', data.data)
									commit('wantUpdateHistogram', false)
								})
							} else {
								state.histogram.loaded = true
							}
						} else {
							state.loadingInProgress = false
							commit('isNothingFound', true)
						}
					}

				})

		},
		resetAll({ state, commit, dispatch }) {
			state.resultsFrom = 0
			state.currentResultPage = 1
			state.totalPages = 0
			state.totalResults = 0
			state.selectedIds = []
			state.figureUploadSearchID = null
			commit('resetLoadingColumns')
			commit('resetSelectedColumns')
			commit('resetSelectedTypesOfFigure')
			commit('resetSortField')
			commit('resetHistogram')
			state.articleTypeSelected = []
			state.textAvailabilitySelected = 'allmixed'
			state.figuresGallery = null
			state.galleryOpen = null
			state.results = null
		}
	},

	mutations: {
		searchText: (state, value) => {
			state.searchText = value
		},

		resetSearchResults(state) {
			if (state.wantResetPagination === true) {
				state.resultsFrom = 0
				state.currentResultPage = 1
				state.totalPages = 0
				state.totalResults = 0
			}
			state.results = null
			state.selectedIds = []
		},
		resetHistogram(state) {
			state.histogram.min = null
			state.histogram.max = null
			state.histogram.data = []
			state.updateHistogram = true
			state.histogram.loaded = false
			state.histogram.changed = false
			state.histogram.histogramloaded = false
		},
		updateSearchResults(state, data) {
			if (data.columninformation.length > state.resultsPerPage) {
				data.columninformation.length = state.resultsPerPage
			}
			state.results = data.columninformation
			state.totalResults = data.totalresults
			//state.totalResults = 126

			let total = state.totalResults / state.resultsPerPage

			if (total < 1) {
				state.totalPages = 0
			} else {
				state.totalPages = total.toFixed(0)
			}
			state.loadingInProgress = false
		},
		updateResultsByUploadImage(state, data) {
			let resultsColumnsData = data.columninformation
			if (resultsColumnsData.length > state.resultsPerPage) {
				resultsColumnsData.length = state.resultsPerPage
			}
			state.results = resultsColumnsData
			state.totalResults = data.totalresults
			state.isNothingFound = !state.totalResults > 0
			state.loadingInProgress = false
			state.searchByUploadModalOpen = false
			if (state.wantResetPagination === true) {
				let total = state.totalResults / state.resultsPerPage
				if (total < 1) {
					state.totalPages = 0
				} else {
					state.totalPages = total.toFixed(0)
				}
			}
		},
		mustPay(state, value) {
			state.isMustPay = value
		},
		updateHistogram(state, value) {
			state.histogram.min = value.hasMin('year').year
			state.histogram.max = value.hasMax('year').year
			//чтобы не выводить какой то год по 100000 раз я вычисляю корень из числа большего 10000
			//но вообще можно улучшить, тут нужен математик
			const dataArray = []

			value.map((item) => {
				let numberWantToSqrt = item.count

				if (numberWantToSqrt > 5000) {
					numberWantToSqrt = Math.round(numberWantToSqrt / 2)
				}
				if (numberWantToSqrt > 10000) {
					numberWantToSqrt = Math.round(Math.sqrt(item.count))
				}
				for (let i = 0; i < numberWantToSqrt; i++) {
					dataArray.push(item.year)
				}
			})
			state.histogram.data = dataArray
			state.histogram.loaded = true
		},
		histogramChanged(state, value) {
			state.histogram.changed = value
		},
		removeColumn: (state, value) => {
			state.columns.find(({ id }) => id === value).active = false
		},
		wantResetPagination: (state, value) => {
			state.wantResetPagination = value
		},
		resetLoadingColumns(state) {
			state.columns.map((item) => {
				item.loaded = false
			})
		},
		resetSelectedColumns(state) {
			state.columns.map((item) => {
				if (item.isDefault === false) {
					item.active = false
				}
			})
		},
		resetSelectedTypesOfFigure(state) {
			state.typeOfFigure.map((item) => {
				item.selected = false
			})
		},
		 updateLoadingColumn(state, value) {
			state.columns.find((x) => x.id === value[0]).loaded = value[1]
		},
		goNextPage: (state, value) => {
			state.currentResultPage++
			state.wantResetPagination = false
			state.resultsFrom += state.resultsPerPage
		},
		goPrevPage: (state, value) => {
			state.wantResetPagination = false
			if (state.currentResultPage <= 1) {
				state.currentResultPage = 0
				state.resultsFrom = 0
			} else {
				state.currentResultPage--
				state.resultsFrom -= state.resultsPerPage
			}
		},

		goPageNumber: (state, pageNumber) => {
			state.wantResetPagination = false
			state.currentResultPage = pageNumber
			state.resultsFrom = (pageNumber - 1) * state.resultsPerPage
		},
		goLastPage: (state, value) => {
			state.wantResetPagination = false
			state.currentResultPage = state.totalPages
			state.resultsFrom = state.totalResults - state.resultsPerPage
		},
		addSelectedId: (state, value) => {
			state.selectedIds.push(value)
		},
		changeResultsPerPage: (state, value) => {
			state.resultsPerPage = value
		},
		removeSelectedId: (state, value) => {
			const pmIndex = state.selectedIds.indexOf(value)
			state.selectedIds.splice(pmIndex, 1)
		},
		updateYearFrom: (state, value) => {
			state.histogram.min = value
		},
		updateYearTo: (state, value) => {
			state.histogram.max = value
		},
		updateArticleTypeSelected: (state, value) => {
			state.articleTypeSelected = value
		},
		updateTextAvailabilitySelected: (state, value) => {
			state.textAvailabilitySelected = value
		},
		updateGalleryImages: (state, picsArray) => {
			state.figuresGallery = picsArray
		},
		openGallery: (state, value) => {
			state.galleryOpen = value
		},
		arcGalleryOpen: (state, value) => {
			state.arcGalleryOpen = value
		},
		arcGalleryData: (state, picsArray) => {
			state.arcGalleryData = picsArray
		},
		updateSortField: (state, value) => {
			state.sortField = value
		},
		resetSortField: (state, value) => {
			state.sortField = {
				name: 'Default',
				query: null
			}
		},
		isFigureSearch: (state, value, commit) => {
			state.isFigureSearch = value
			if (state.isFigureSearch === true) {
				state.columns.map((item) => {
					item.loaded = false
					if (item.isDefault === false) {
						item.active = false
					}
				})
			}
		},
		isNothingFound: (state, value) => {
			state.isNothingFound = value
		},
		wantUpdateHistogram: (state, value) => {
			state.updateHistogram = value
		},
		updateTypeOfFigures: (state, value) => {
			state.typeOfFigure = value
		},
		searchByFigureUpload: (state, value) => {
			state.searchByFigureUpload = value
		},
		searchByFigureModalOpen: (state, value) => {
			state.searchByUploadModalOpen = value
		},
		uploadedFileToSearch: (state, value) => {
			state.uploadedFileToSearch = value
		},
		figureUploadSearchID: (state, value) => {
			state.figureUploadSearchID = value
		}
	},

	getters: {
		searchText(state) {
			return state.searchText
		},
		totalResults(state) {
			return state.totalResults
		},
		hasResults(state) {
			return state.totalResults > 0
		},
		resultsData(state) {
			return state.results
		},
		isLoading(state) {
			return state.loadingInProgress
		},
		isLoadingColumns(state) {
			return state.loadedAdditionColumn
		},
		allColumns(state) {
			return state.columns
		},
		selectedIds(state) {
			return state.selectedIds
		},
		resultsPerPage(state) {
			return state.resultsPerPage
		},
		totalPages(state) {
			return state.totalPages
		},
		currentResultPage(state) {
			return state.currentResultPage
		},
		resultsFrom(state) {
			return state.resultsFrom
		},
		histogramData(state) {
			return state.histogram.data
		},
		histogramMin(state) {
			return state.histogram.min
		},
		histogramMax(state) {
			return state.histogram.max
		},
		histogramLoaded(state) {
			return state.histogram.loaded
		},
		galleryOpen(state) {
			return state.galleryOpen
		},
		figuresGallery(state) {
			return state.figuresGallery
		},
		arcGalleryOpen(state) {
			return state.arcGalleryOpen
		},
		arcGalleryData(state) {
			return state.arcGalleryData
		},
		availableSortFields(state) {
			return state.sortFields.available
		},
		selectedSortField(state) {
			return state.sortField
		},
		isFigureSearch: (state) => {
			return state.isFigureSearch
		},
		isNothingFound: (state) => {
			return state.isNothingFound
		},
		wantUpdateHistogram: (state) => {
			return state.updateHistogram
		},
		typeOfFigure: (state, value) => {
			return state.typeOfFigure
		},
		isAllAdditionColumnsLoaded: (state) => {
			return !state.columns.find(
				({ isDefault, active, loaded }) => isDefault === false && active === true && loaded === false
			)
		},
		searchByFigureUpload: (state, value) => {
			return state.searchByFigureUpload
		},
		searchByFigureModalOpen: (state, value) => {
			return state.searchByUploadModalOpen
		},
		uploadedFileToSearch: (state, value) => {
			return state.uploadedFileToSearch
		},
		figureUploadSearchID: (state, value) => {
			return state.figureUploadSearchID
		},
		textAvailabilityVariants: (state, value) => {
			return state.textAvailability
		},
		textAvailabilitySelected: (state, value) => {
			return state.textAvailabilitySelected
		},
		isMustPay: (state, value) => {
			return state.isMustPay
		}
	}
}

<script setup>
import './vendor-window.scss'
import { onMounted, onUnmounted, computed, onUpdated, inject, watch, ref } from 'vue'
import ModalBox from '@/components/modal-box/modal-box.vue'
import { useStore } from 'vuex'
import { api } from '@/arcella-api/api.js'
const isLoading = ref(true)
const requested = ref(false)
const store = useStore()

const vendorKeys = ref([])
const addBtnDisabled = ref(false)
const progressSend = ref(false)
const user = computed(() => {
	return store.getters['interfaceSettings/userData']
})
watch(
	() => vendorKeys.value,
	(vendorKeys) => {
		console.log(vendorKeys)
		if( vendorKeys.length > 0){
			if(vendorKeys[vendorKeys.length-1].length < 2){
				addBtnDisabled.value = true
			}
			else{
				addBtnDisabled.value = false
			}
		}
	},
	{ immediate: false, deep: true }
)
onMounted(() => {
	api.userservices.getVendorsAddedEmails(user.value.apiKey).then((response) => {
		vendorKeys.value = response.data
		isLoading.value = false
		console.log(vendorKeys.value.length)

	})
})
function validateEmail(value) {
	return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
}
function handleModalClose() {
	store.commit('interfaceSettings/vendorKeysWindowShowed', false)
}
function handleAddItem(){

	vendorKeys.value.push([''])

}
function handleSaveItem(element){
	requested.value = true
	let elementInput = element.target.closest("tr").getElementsByClassName('form-control')[0]
	let currentInputValue = elementInput.value
	let validation = validateEmail(currentInputValue)
	if(!validation){
		elementInput.classList.add('has-error')
	}
	else{
		elementInput.classList.remove('has-error')
		api.userservices.addVendorEmail(currentInputValue, user.value.apiKey ).then((response) => {
			requested.value = false
			if(response.data === true){
				vendorKeys.value[vendorKeys.value.length-1] = currentInputValue
			}
			else{
				alert('error while trying to add vendor email')
			}
		})
	}




}
function handleRemoveItem(email,id){
	requested.value = true
	api.userservices.deleteVendorEmail(email, user.value.apiKey ).then((response) => {
		requested.value = false
		if(response.data === true){
			vendorKeys.value.splice(id, 1)
		}
		else{
			alert('error removing vendor email')
		}
	})
}
</script>

<template>
	<modal-box class-name="vendor-keys-modal"  @close="handleModalClose">
		<div class="modal-heading">
			<span class="modal-window-title">Manage users</span>
		</div>
		<form class="vendor-keys-form" :class="[{ 'loading': isLoading === true }, { 'requested': requested === true }]"  ref="formElem" >
			<div class="loader-pic">
				<img src="@/assets/images/vector/curve-spinner.svg" alt="">
			</div>
			<div class="table-wrap" v-if="!isLoading">
				<table class="table" >
					<thead>
					<tr>
						<td>Id</td>
						<td>Email</td>
						<td>Action</td>
					</tr>
					</thead>
					<tbody>
					<template v-for="(vendorEmailItem, i) of vendorKeys" :key="i">
						<tr>
							<td><strong>{{i+1}}</strong></td>
							<td>
								<input
									class="form-control"
									placeholder="Type registered user email"
									type="email"
									name="name"
									:value="vendorEmailItem"
									ref="emailInput"
									aria-label="Name"
									required
									:readonly="vendorEmailItem.length > 1"
								/>

							</td>
							<td>
								<div class="btn-group">
									<button class="btn btn-save" type="button" v-if="vendorEmailItem.length < 2" @click="handleSaveItem"><i></i><span>Save</span></button>
									<button class="btn btn-remove" type="button" v-else @click="handleRemoveItem(vendorEmailItem, i)"><i><img src="@/assets/images/vector/trash-alt.svg" alt=""></i><span>Remove</span></button>
								</div>
							</td>
						</tr>
					</template>


					</tbody>
				</table>
				<div class="add-btn-wrap">
					<button class="add-user"  v-if="vendorKeys.length < 5" :disabled="addBtnDisabled === true" type="button" @click="handleAddItem">
						<i></i>
						<span>Add user</span>
					</button>
				</div>
			</div>



		</form>


	</modal-box>
</template>

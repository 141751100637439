export function downloadTSV(resultsData, columns, filename) {
	let headersArray = ['Sentence'] // Sentence is default value, we fill that in any case
	let dataArray = []
	columns.map((item) => {
		if (item.active === true) {
			headersArray.push(item.name)
		}
	})
	headersArray.splice(1, 0, 'doi');


	dataArray.push(headersArray)

	function arrayToCsv(data) {
		return data
			.map(
				(row) =>
					row
						.map(String) // convert every value to String
						.map((v) => v.replaceAll('"', '""')) // escape double quotes
						.map((v) => `"${v}"`) // quote it
						.join(',') // comma-separated
			)
			.join('\r\n') // rows starting on new lines
	}

	function downloadBlob(content, filename, contentType) {
		// Create a blob
		const blob = new Blob([content], { type: contentType })
		const url = URL.createObjectURL(blob)

		// Create a link to download it
		const pom = document.createElement('a')
		pom.href = url
		pom.setAttribute('download', filename)
		pom.click()

	}


	//add doi

	resultsData.forEach((item) => {
		const row = headersArray.map((header) => {
			if (header in item.data) {
				//если у нас доп колонка (в которой объект у меня всегда), то тогда берем данные с объекта
				if (typeof item.data[header] == 'object') {
					if (item.data[header].result) {
						if(header === 'Emails'){
							let emails = ''
							item.data[header].result.forEach((item) => {
								emails += `${item}\n`
							})
							return emails
						}
						else if(header === 'Institutions'){
							let institutions = ''
						 	const instArray = item.data[header].result.split('\n').filter(item => item.trim() !== '')
							instArray.forEach((item) => {
								institutions += `${item}\n`
							})
							return institutions
						}
						else{
							return item.data[header].result
						}

					} else {
						return ''
					}
				}
				//если простая дефолтная колонка
				else {
					return item.data[header]
				}
			}
			if(header === 'doi'){
				return 'https://doi.org/'+item.doi
			}

		})


		dataArray.push(row)

	})

	const tsvData = arrayToCsv(dataArray)
	downloadBlob(tsvData, filename + '.tsv', 'data:text/csv;charset=utf-8,')


}
